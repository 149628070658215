<template>
  <div class="wr print-page-component">
    <div class="page page-a4-landscape-auto" style="height: auto !important; min-height: 220mm">
      <table class="print-friendly">
        <thead>
          <tr>
            <th class="grow">Адреса</th>
            <th style="min-width: 80px; width: 80px">Особовий рахунок</th>
            <th style="min-width: 130px; width: 130px">Власник</th>
            <th style="min-width: 60px; width: 60px">Термін заборг.(міс.)</th>
            <th>Сальдо на початок</th>
            <th>До оплати</th>
            <th>Оплачено</th>
            <th>Сальдо на поч. з урах. оплати</th>
            <th>Дата оплати</th>
            <th>Сальдо на кінець</th>
          </tr>
        </thead>
        <tbody v-for="(item, idx) in items"
               :key="`group-${idx}`"
               :class="`${item.items ? item.class + ' details' : item.class}`"
               :style="item.level <= (max_lvl - 1) ? '' : 'display: none'"
        >
          <template v-if="!item.items">
            <tr :class="`bg-level-${item.level}`">
              <td class="grow" colspan="4"
                  :style="`padding-left: ${item.level === 0 ? 8 : item.level * 16}px`"
              >
                <v-icon size="14" class="mr-1" color="grey darken-3"
                        @click.stop="showRows(item.class, `button-${idx}`)"
                        :id="`button-${idx}`"
                >
                  {{ getCollapseIcon(item.level) }}
                </v-icon>
                {{ item.group_value }}
              </td>
              <td>{{ item.start_balance | formatNumber }}</td>
              <td>{{ item.charge | formatNumber }}</td>
              <td>{{ item.pay | formatNumber }}</td>
              <td>{{ item.start_balance_minus_pay | formatNumber }}</td>
              <td></td>
              <td>{{ item.end_balance | formatNumber }}</td>
            </tr>
          </template>
          <template v-else>
            <tr
                v-for="(subitem, subidx) in item.items"
                :key="`item-${idx}-${subidx}`"
            >
              <td class="grow">{{ subitem.address }}</td>
              <td style="min-width: 80px; width: 80px">{{ subitem.person_id }}</td>
              <td style="min-width: 130px; width: 130px">{{ subitem.owner }}</td>
              <td style="min-width: 60px; width: 60px">{{ subitem.debt_month }}</td>
              <td>{{ subitem.start_balance | formatNumber }}</td>
              <td>{{ subitem.charge | formatNumber }}</td>
              <td>{{ subitem.pay | formatNumber }}</td>
              <td>{{ subitem.start_balance_minus_pay | formatNumber }}</td>
              <td>{{ subitem.pay_date | formatDate }}</td>
              <td>{{ subitem.end_balance | formatNumber }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import reportAPI from "@/utils/axios/reports"

export default {
  name: "HtmlTable",
  data() {
    return {
      header: [],
      max_lvl: 0,
      items: []
    }
  },
  methods: {
    getCollapseIcon(level) {
      return level < this.max_lvl - 1 ? 'mdi-minus' : 'mdi-plus'
    },
    showRows(group_class, button_id) {
      const button = document.getElementById(button_id)
      const close = button.classList.contains('mdi-plus')
      const groups = document.getElementsByClassName(group_class)
      const group_split = group_class.split(' ')
      let group_without = []

      groups.forEach(el => {
        const cls_name = el.className.split(' ')
        if (close) {
          if (cls_name.length === group_split.length + 1) {
            if (!group_without.includes(el)) {
              group_without.push(el)
            }
          }
        } else {
          if (el.className.trim() !== group_class.trim()) {
            if (!group_without.includes(el)) {
              group_without.push(el)
            }
          }
        }
      })

      if (close) {
        button.classList.remove('mdi-plus')
        button.classList.add('mdi-minus')
        group_without.forEach(el => {
          el.style.display = ''
        })
      } else {
        button.classList.remove('mdi-minus')
        button.classList.add('mdi-plus')
        group_without.forEach(el => {
          el.style.display = 'none'
          el.getElementsByTagName('button').forEach(btn => {
            btn.classList.remove('mdi-minus')
            btn.classList.add('mdi-plus')
          })
        })
      }
    }
  },
  created() {
    const payload = {
      date_start: '2021-11-01',
      date_end: '2021-11-30',
      filters: {}
    }
    reportAPI.debt_report_by_flat_new(payload)
      .then(response => response.data)
      .then(data => {
        let max_lvl = 0
        data.forEach(row => {
          if (row.level >= this.max_lvl) {
            max_lvl = row.level
          }
        })
        this.max_lvl = max_lvl
        this.items = data
      })
  }
}
</script>

<style scoped lang="scss">
  table, tr, th, td {
    border: 1px solid #cbcbcb;
    border-collapse: collapse;
  }
  th {
    background: #f3f3f3;
    font-weight: 500 !important;
  }
  th, td {
    padding: 5px;
    font-size: 11px;
    font-weight: 400;
    width: 140px;
  }
  th.grow, td.grow {
    width: 180px;
    min-width: 180px;
  }
  tbody {
    tr {
      td {
        text-align: center;

        &:nth-child(1) {
          text-align: left;
          padding-left: 14px;
        }
      }
    }
  }
  :deep(td button:focus) {
    background: none !important;
  }
  .bg-level-0 {
    background: #e2e2e2;
  }
  .bg-level-1 {
    background: #f4f4f4;
  }
  .bg-level-2 {
    background: #f6f6f6;
  }
  .bg-level-3 {
    background: #f8f8f8;
  }
</style>